import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { SEO, devices } from '../components/common';
import { KelaPalvelutPage, Psychotherapy } from '../@types/types';
import HeroSection from '../components/HeroSection';
import SimpleAccordion from '../components/Accordion';
import Article from '../components/Article';

type Props = {
  data: {
    page: KelaPalvelutPage;
    kelaServices: { nodes: Psychotherapy[] };
    arrow: { publicURL: string };
  };
  location: Location;
};

const KelaServices = ({ data, location }: Props) => {
  const {
    page: { MetaData, PageTopSection, ServicesTitle, ServicesDescription },
    kelaServices: { nodes },
    arrow,
  } = data;

  const theme = useTheme();

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />

      {ServicesTitle && ServicesDescription && (
        <Article
          title={ServicesTitle}
          content={ServicesDescription}
          backgroundColor={theme.colors.mutedLightBlue}
        />
      )}

      <AccordionContainer>
        <ArrowContainer>
          <ArrowImage src={arrow.publicURL} alt="picture of an arrow" />
          <Section>
            {nodes.map((item, index) => (
              <SimpleAccordion
                key={index}
                header={item.Title}
                text={item.Description}
              />
            ))}
          </Section>
        </ArrowContainer>
      </AccordionContainer>
    </>
  );
};

export default KelaServices;

export const expertQuery = graphql`
  query KelaServicesPageQuery {
    page: strapiKelaPalvelut {
      PageTopSection {
        PageTitle
        TopSectionText
      }
      MetaData {
        MetaDescription
        MetaTitle
        metaKeywords
      }
      ServicesTitle
      ServicesDescription
    }
    kelaServices: allStrapiKelaServices {
      nodes {
        Description
        Title
        strapiId
      }
    }
    arrow: file(name: { eq: "blue-arrow" }) {
      publicURL
    }
  }
`;

const Section = styled.div`
  padding: ${(props) => props.theme.spacing.medium} 0px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.laptop} {
    padding: ${(props) => props.theme.spacing.medium} 0px;
    width: 650px;
  }

  @media ${devices.mobileXL} {
    width: 90% !important;
  }
`;

const AccordionContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding-bottom: ${(props) => props.theme.spacing.xxxlarge};
  position: relative;
`;

const ArrowContainer = styled.div`
  width: 85%;
  max-width: 1300px;
  margin: auto;
  position: relative;

  @media ${devices.laptopL} {
    width: 100%;
  }
`;

const ArrowImage = styled.img`
  width: 120px;
  height: 160px;
  position: absolute;
  left: 0px;
  top: 115px;

  @media ${devices.laptopL} {
    display: none;
  }
`;
